<template>
    <div >
        <p class="my-6 text-center text-lg md:text-2xl font-bold">Profil</p>
        <div class="bg-white rounded-lg border border-innerBorder p-4 md:p-6 w-10/12 sm:w-2/3 xl:w-1/2 mx-auto">
            <div class="flex space-x-4 md:space-x-6 items-center mb-4">
                <p class="bg-white">
                    <img v-if="user.profile" class="h-14 w-14 rounded-full" :src="fileUrl+`${user.profile.name}`" alt="">
                    <img v-else class="h-14 w-14 rounded-full" src="@/assets/svg/avatar.svg" alt="">
                </p>
                <p>{{user.lastname }} {{ user.firstname}}</p>
            </div>
            <div class="flex space-x-4">
                <MButton @click="nameChangedModal = true" :classes="'px-3 py-2 text-xs bg-innerBorder rounded-md'" :text="'Changer mon nom'"></MButton>
                <div>
                    <input class="w-full opacity-0  overflow-hidden absolute focus:outline-none" type="file" @change="changeProfil" title=" " ref="file" accept=".jpg,.jpeg,.png">
                    <MButton :classes="'px-3 py-2 text-xs bg-innerBorder rounded-md'" :text="'Changer mon avatar'"></MButton>
                </div>
            </div>
            <hr class="bg-innerBorder mt-6 mb-4">
            <div class="">
                <p class="font-medium mb-4">Informations de connexion</p>
                <p class="flex flex-col">
                    <span class="text-fonctGrey">Email</span>
                    <span class="font-medium mb-4">{{user.username}}</span>
                </p>
                <MButton @click="pwdChangedModal = true" :classes="'px-3 py-2 text-xs bg-innerBorder rounded-md'" :text="'Changer mon mot de passe'"></MButton>
            </div>
        </div>
        <Modal ref="modal" centered size="sm" :css="deliveryCss" :show="nameChangedModal">
            <div slot="modal-header">
                <div class="flex space-x-24 font-medium justify-between" @click="nameChangedModal=false">
                    <p class=" text-xl md:text-2xl">Changer le nom</p>
                    <p class="cursor-pointer"><svgicon name="closeMod" class="h-6 md:h-8 w-6 md:w-8"></svgicon></p>
                </div>
            </div>
            <div slot="modal-body" class="mt-4">
                 <ValidationObserver ref="form" tag="div"  class="">
                    <form @submit.prevent="onNameChangedSubmit">
                        <div class="mb-5">
                            <ValidationProvider name="Le nom" rules="required" v-slot="{ errors }">
                                <m-input name = "name" :errors="errors[0]" v-model="userUpdate.lastName" label="Nom"/>
                            </ValidationProvider>
                        </div>
                        <div class="mb-5">
                            <ValidationProvider name="Le(s) prénom(s)" rules="required" v-slot="{ errors }">
                                <m-input name = "firstName" :errors="errors[0]" v-model="userUpdate.firstName" label="Prénoms"/>
                            </ValidationProvider>
                        </div>
                        <div class="grid grid-cols-2 gap-4">
                            <m-button @click="nameChangedModal=false" :classes="'font-bold text-sm sm:text-base bg-bgGrey rounded-lg p-2 w-full'" :text="'Annuler'"></m-button>
                            <m-button :type="'submit'" :classes="'text-white font-bold text-sm sm:text-base bg-primary rounded-lg p-2 w-full'" load :activateLoad="request" :text="'Valider'"></m-button>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </Modal>
         <Modal ref="modal" centered size="sm" :css="deliveryCss" :show="pwdChangedModal">
            <div slot="modal-header">
                <div class="flex space-x-24 font-medium justify-between" @click="pwdChangedModal=false">
                    <p class=" text-xl md:text-2xl">Changer le mot de passe</p>
                    <p class="cursor-pointer"><svgicon name="closeMod" class="h-6 md:h-8 w-6 md:w-8"></svgicon></p>
                </div>
            </div>
            <div slot="modal-body" class="mt-4">
                 <ValidationObserver ref="pwdForm" tag="div"  class="">
                    <form @submit.prevent="onPwdSubmit">
                        <div class="mb-5">
                            <ValidationProvider name="L'ancien mot de passe" rules="required" v-slot="{ errors }">
                                <m-input name = "pwd" :errors="errors[0]" v-model="oldPassword" :type="'password'" label="Votre ancien mot de passe">
                                    <!-- <div class="cursor-pointer" slot="append" @click="showPwd = !showPwd">
                                        <svgicon name="eye" color="#5F738C" width="20" height="20"  v-if="!showPwd"></svgicon>
                                        <svgicon name="eyeOff" color="#5F738C" width="20" height="20" v-else></svgicon>
                                    </div> -->
                                </m-input>
                            </ValidationProvider>
                            <p v-if="invalidOldPwd" class="flex items-center space-x-1.5 mt-2">
                                <span>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="1" width="14" height="14">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00004 1.3335C4.32004 1.3335 1.33337 4.32016 1.33337 8.00016C1.33337 11.6802 4.32004 14.6668 8.00004 14.6668C11.68 14.6668 14.6667 11.6802 14.6667 8.00016C14.6667 4.32016 11.68 1.3335 8.00004 1.3335ZM8.00004 8.66683C7.63337 8.66683 7.33337 8.36683 7.33337 8.00016V5.3335C7.33337 4.96683 7.63337 4.66683 8.00004 4.66683C8.36671 4.66683 8.66671 4.96683 8.66671 5.3335V8.00016C8.66671 8.36683 8.36671 8.66683 8.00004 8.66683ZM7.33337 10.0002V11.3335H8.66671V10.0002H7.33337Z" fill="black" />
                                        </mask>
                                        <g mask="url(#mask0)">
                                            <rect width="16" height="16" fill="#DA1414" />
                                        </g>
                                    </svg>
                                </span>
                                <span>Ancien mot de passe invalide</span>
                            </p>
                        </div>
                        <div class="mb-5">
                            <ValidationProvider name="Le nouveau mot de passe" rules="required|upCase|oneNumber|oneCharac|min:8|confirmed:confirmation" v-slot="{ errors }">
                                <m-input name = "pwd" :errors="errors[0]" v-model="password" :type="'password'" label="Votre nouveau mot de passe">
                                    <!-- <div class="cursor-pointer" slot="append" @click="showPwd = !showPwd">
                                        <svgicon name="eye" color="#5F738C" width="20" height="20"  v-if="!showPwd"></svgicon>
                                        <svgicon name="eyeOff" color="#5F738C" width="20" height="20" v-else></svgicon>
                                    </div> -->
                                </m-input>
                            </ValidationProvider>
                        </div>
                        <div  v-if="!validPassword">
                            <p class="text-xs mb-2">Votre mot de passe doit contenir au moins : </p>
                            <div class="grid grid-rows-2 grid-flow-col gap-1 mb-5 text-11">
                                <div class="flex flex-row items-center"><svgicon name="online" :color="validMsg.carac ? '#27AE60' : '#5F738C'" width="4" height="4"></svgicon><span class="ml-1" :class="validMsg.carac ? '' : 'text-inkLight-active'">Un caractère spécial(#?*+/)</span></div>
                                <div class="flex flex-row items-center"><svgicon name="online" :color="validMsg.num ? '#27AE60' : '#5F738C'" width="4" height="4"></svgicon><span class="ml-1" :class="validMsg.num ? '' : 'text-inkLight-active'">Un chiffre</span></div>
                                <div class="flex flex-row items-center"><svgicon name="online" :color="validMsg.upper ? '#27AE60' : '#5F738C'" width="4" height="4"></svgicon><span class="ml-1" :class="validMsg.upper ? '' : 'text-inkLight-active'">Une lettre majuscule</span></div>
                                <div class="flex flex-row items-center"><svgicon name="online" :color="validMsg.eightCarac ? '#27AE60' : '#5F738C'" width="4" height="4" ></svgicon><span class="ml-1" :class="validMsg.eightCarac ? '' : 'text-inkLight-active'">8 caractères minimum</span></div>
                            </div>
                        </div>
                        
                        <div class="mb-5">
                            <ValidationProvider name="La confirmation du mot de Passe" rules="required" v-slot="{ errors }" vid="confirmation">
                                <m-input :errors="errors[0]" v-model="passwordConfirmation" name = "pwdConfirm" :type="'password'" label="Confirmer votre nouveau mot de passe">
                                    <!-- <div class="cursor-pointer" slot="append" @click="showConfirmPwd = !showConfirmPwd">
                                        <svgicon name="eye" color="#5F738C" width="20" height="20"  v-if="!showConfirmPwd"></svgicon>
                                        <svgicon name="eyeOff" color="#5F738C" width="20" height="20" v-else></svgicon>
                                    </div> -->
                                </m-input>
                            </ValidationProvider>
                        </div>
                        <div class="grid grid-cols-2 gap-4">
                            <m-button @click="pwdChangedModal=false" :classes="'font-bold text-sm sm:text-base bg-bgGrey rounded-lg p-2 w-full'" :text="'Annuler'"></m-button>
                            <m-button :type="'submit'" :classes="'text-white font-bold text-sm sm:text-base bg-primary rounded-lg p-2 w-full'" load :activateLoad="request" :text="'Valider'"></m-button>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </Modal>
    </div>
</template>

<script>
import axios from 'axios'
const token = localStorage.getItem('token')
const header = { "Authorization": `Bearer ${token}` }
const instanceApi = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL,
    headers: header
})
export default {
    data() {
        return {
            fileUrl: process.env.VUE_APP_BACKEND_URL+'files/download/',
            nameChangedModal: false,
            pwdChangedModal: false,
            deliveryCss: {modalClass:"p-4 md:px-6 overflow-x-hidden overflow-y-auto hiddScroll rounded-lg"},
            userUpdate:{
                lastName: '',
                firstName: ''
            },
            request: false,
            validMsg: {
                carac: false,
                num: false,
                upper: false,
                eightCarac: false
            },
            oldPassword: '',
            password: '',
            passwordConfirmation: '',
            validPassword: true,
            invalidOldPwd: false,
            
        }
    },
    watch: {
        oldPassword(){
            this.invalidOldPwd = false
        },
        password(value){
            this.password = value;
            this.validatePassword(value);
            if (this.password.length >= 8 && this.validMsg.carac && this.validMsg.num && this.validMsg.upper && this.validMsg.eightCarac) {
                setTimeout(() => {
                    this.validPassword = true
                }, 2000);
            }
            else if (this.password.length == 0) {
                this.validPassword = true
            }
            else {
                this.validPassword = false
            }
        }
    },

    components: {

    },
    computed: {
        user(){
            return this.$store.getters.getUser
        }
    },
    methods: {
        changeProfil(){
            const file = this.$refs.file.files[0];
            const name = file.name
            const formData = new FormData()
            formData.append('name',name)
            formData.append('file', file)
            instanceApi.request({
                headers:{},
                method: "post",
                url: `/files`,
                data: formData,
            })
            .then(res => {
                this.updateProfil(res.data.id)
            })
            .catch(err => {
                console.log(err)
            })
        },
        updateProfil(id){
            instanceApi.put(`/users/${this.user.id}`,{
                profile: id,
            })
            .then( res => {
                console.log(res)
                const userData = res.data
                this.$store.commit('mutUserData', {
                    profile: userData.profile,
                    id: userData.id, 
                    username: userData.username, 
                    lastname: userData.lastName,
                    firstname: userData.firstName
                })
            })
            .catch( err => {
                console.log(err.response)
            })
        },
        validatePassword(value){
            let eigh = /.{8,}/;
            let numreq = /[0-9]+/;
            let upp = /[A-Z]+/;
            let car = /^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/;
            eigh.test(value) ? this.validMsg.eightCarac = true : this.validMsg.eightCarac = false;

            numreq.test(value) ? this.validMsg.num = true : this.validMsg.num = false;
            
            upp.test(value) ? this.validMsg.upper = true : this.validMsg.upper = false;
            
            car.test(value) ? this.validMsg.carac = true : this.validMsg.carac = false;
            
        },
        onNameChangedSubmit(){
            this.$refs.form.validateWithInfo().then(validate => {
                let { isValid, errors } = validate;
                console.log(isValid, errors)
                if(isValid) {
                    this.request = true
                    instanceApi.put(`/users/${this.user.id}`,{
                        lastName: this.userUpdate.lastName,
                        firstName: this.userUpdate.firstName
                    })
                    .then( res => {
                        this.request = false
                        console.log(res)
                        const userData = res.data
                        this.$store.commit('mutUserData', {
                            profile: userData.profile,
                            id: userData.id, 
                            username: userData.username, 
                            lastname: userData.lastName,
                            firstname: userData.firstName
                        })
                        this.nameChangedModal = false
                    })
                    .catch( err => {
                        this.request = false
                        console.log(err.response)
                    })
                }
                
               
            })
        },
        onPwdSubmit(){
            this.$refs.pwdForm.validateWithInfo().then(validate => {
                let { isValid, errors } = validate;
                console.log(isValid, errors)
                if(isValid) {
                    this.request = true
                    instanceApi.put(`/users/${this.user.id}`,{
                        password: this.oldPassword,
                        newpassword: this.password,
                    })
                    .then( res => {
                        this.request = false
                        console.log(res)
                        this.pwdChangedModal = false
                    })
                    .catch( err => {
                        this.request = false
                        console.log(err.response)
                        if(err.response.status == 400) this.invalidOldPwd = true
                    })
                }
                
               
            })
        }
    },
    mounted(){
        this.$emit('emitBg', 'bg-bgGrey')
        this.userUpdate = {
            lastName: this.user.lastname,
            firstName: this.user.firstname,
        } 
    }
}
</script>

<style scoped>

</style>